@mixin transition_resize_any($elems, $properties...) {
  @each $elem in $elems {
    #{$elem} {
      transition-property: $properties;
      transition-timing-function: ease-in-out;
      transition-duration: $animation_duration_structure;
    }
  }
}

@mixin transition_resize($elems, $properties...) {
  @each $elem in $elems {
    .no-touchevents.show-resize-anim #{$elem} {
      transition-property: $properties;
      transition-timing-function: ease-in-out;
      transition-duration: $animation_duration_structure;
    }
  }
}
