@mixin willchange($elems, $properties) {
  @each $elem in $elems {
    .willchange #{$elem} {
      will-change: #{$properties};
    }
    html:not(.willchange) #{$elem} {
      transform: translateZ(0);
      // transform: translate3d(0);
    }
  }
}
