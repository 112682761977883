// a, a:link, a:visited, a:hover, a:focus, a:active {
//   Default State
// }
//
// .no-touchevents a:hover {
//   Hover State
// }
//
// html:not(.touchscrolled) a:active, .no-touchevents a:active {
//   Click or Press State
// }

@mixin interactive_default($elems) {
  @each $elem in $elems {
    #{$elem},
    #{$elem}:link,
    #{$elem}:visited,
    #{$elem}:hover,
    #{$elem}:focus,
    #{$elem}:active {
      @content;
    }
  }
}

@mixin interactive_hover($elems) {
  @each $elem in $elems {
    .no-touchevents #{$elem}:hover {
      @content;
    }
  }
}

@mixin interactive_active($elems) {
  @each $elem in $elems {
    html:not(.touchscrolled) #{$elem}:active,
    .no-touchevents #{$elem}:active {
      @content;
    }
  }
}

@mixin interactive_hover_conditional($elems, $conditional) {
  @each $elem in $elems {
    #{$conditional}.no-touchevents #{$elem}:hover {
      @content;
    }
  }
}

@mixin interactive_active_conditional($elems, $conditional) {
  @each $elem in $elems {
    html#{$conditional}:not(.touchscrolled) #{$elem}:active,
    #{$conditional}.no-touchevents #{$elem}:active {
      @content;
    }
  }
}

// Add custom element states:
@mixin interactive_custom_state($elems) {
  @each $elem in $elems {
    html:not(.touchscrolled) #{$elem},
    .no-touchevents #{$elem} {
      @content;
    }
  }
}
