// Cross browser opacity
// Usage
// .faded-text {
//   @include opacity(0.8);
// }
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin opacity-important($opacity) {
  opacity: $opacity !important;
  $opacity-ie: $opacity * 100 !important;
  filter: alpha(opacity=$opacity-ie) !important; //IE8
}
