// Function

@import '../function/interactive';

// Variable

@import '../variable';

// Mixin

@import '../mixin/opacity';
@import '../mixin/resize';
@import '../mixin/willchange';

// Correction

// @import '../mixin/reset';

// @include reset-css('[class^="pswp"]', '[class*="pswp"]');

[class*=pswp] {
  font-family: $font_family !important;
  font-size: inherit !important;
}

.pswp section {
  background-color: transparent !important;
  border-radius: 0px;
}

.pswp__container {
  margin: 0px !important;
}

.pswp__top-bar {
  padding: 0px;
  margin: 0px !important;
}

// Cleanup

.pswp svg {
  pointer-events: none;
  cursor: pointer;

  use {
    pointer-events: none;
    cursor: pointer;
  }
}

.pswp__img {
  border-radius: 0px;
}

// Theme

// button

@include transition_resize_any( ('.pswp__button'), opacity );

@include interactive_default(('.pswp__button')) {
  background: #7f7f7f5f !important;
  border-radius: $radius_1;
  margin: $page_gap_1 $page_gap_1 !important;
  padding: $page_gap_1 !important;
  
  height: 50px;
  
  @include opacity(1);
  @include button_box_shadow_default;

  transition-duration: $animation_duration_interact !important;
  color: #ffffff !important;

  display: inline-block;

  &.pswp__button--open {
    // background: #7f7f7f5f !important;
    color: red;
  }
}

@include interactive_hover(('.pswp__button')) {
  @include opacity(0.65);
  @include button_box_shadow_hover;
}

@include interactive_active(('.pswp__button')) {
  @include opacity(0.4);
  @include button_box_shadow_active;
}

@include willchange(('.pswp__button'), opacity );

@include interactive_default(('.pswp__button--arrow')) {
  top: calc(50% - (40px + $page_gap_1));
  width: 60px;
  height: 80px;
  position: absolute !important;
}

.pswp__button--arrow--prev {
  .pswp__icn {
    left: $page_gap_1;
  }
}

.pswp__button--arrow--next {
  .pswp__icn {
    right: $page_gap_1;
  }
}

// button icon

.pswp__icn {
  top: 9px;
}

// preloader

.pswp__preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  pointer-events: none;

  width: 128px;
  height: 128px;

  opacity: 0.625;

  .pswp__icn {
    // opacity: 1 !important; // testing
    animation: none !important;

    background-image: url("/asset/image/interface/animation/busy/001_light_128px.gif");

    width: 128px;
    height: 128px;

    top: 0;
    left: 0;

    path {
      display: none !important;
    }
  }
}

.pswp__counter {
  background: #2222227f;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  
  padding: $page_gap_2;
  border-radius: $radius_1;

  // left position

  // position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  height: 60px;
  margin: $page_gap_1;
  margin-right: auto;
  height: 50px;
  padding: 16px;
  line-height: 20px;

  // center position

  // position: absolute;
  // left: 0;
  // right: 0;
  // text-align: center;
  // line-height: 3em;
}

.pswp__top-bar {
  height: 100%;
}

// Blurred background 

// (Must animate opacity of the backdrop filter AND background color 
// instead of the whole element's opacity for this to work.):
//  backdrop-filter: blur(4px) opacity(0);

// .backdropfilter .pswp, .backdropfilter .pswp__bg {
//   will-change: initial !important;
// }

// .backdropfilter .pswp__bg {
//   background: transparent;
//   opacity: initial !important;
//   backdrop-filter: blur(10px);
//   background: rgba($color: #000000, $alpha: 0.5);
// }

// caption

.pswp__custom-caption {
  background: #2222227f;
  color: #fff;
  font-size: 1rem;
  line-height: 1;
  
  padding: $page_gap_2;
  border-radius: $radius_1;

  width: calc(100% - $page_gap_2);
  max-width: 400px;
  position: absolute;
  left: 50%;
  bottom: $page_gap_1;
  transform: translateX(-50%);
  text-align: center;
}

// zoom indicator

.pswp__zoom-level-indicator {
  background: #2222227f;
  color: #fff;
  font-size: 1rem;
  line-height: $page_gap_2;

  padding: $page_gap_1;
  border-radius: $radius_1;

  margin: $page_gap_1;
  height: 50px;
  width: 8ch;

  span {
    display: block;
    text-align: center;
  }

  .label {
    font-size: 0.75em;
  }
}

// Idle fade

.pswp {
  // .pswp__top-bar,
  // .pswp__button--arrow,
  // .pswp__custom-caption {
  //   opacity: 1;
  // }

  &.idle-fade {
    &, .pswp__img, .pswp__button {
      cursor: none !important;
    }

    .pswp__top-bar,
    .pswp__button--arrow,
    .pswp__custom-caption {
      opacity: 0;
      transition: opacity 1s ease;
    }
  }
}

@include willchange(('.pswp__top-bar', '.pswp__button--arrow', '.pswp__custom-caption'), opacity);

// fullscreen

.pswp__button--fs span {
  line-height: 34px !important;
  font-size: 1.2em !important;
}

// animation conditional

.pswp[class*=pswp--animation] {
  .pswp__img {
    // cursor: initial !important;
    cursor: initial;
  }
}

.pswp--animation-close {
  .pswp__button {
    pointer-events: none !important;
  }
}

// share

.pswp__menu {
  display: none;
  position: absolute;

  &.pswp__menu--open {
    display: block;
  }
}

#pswp__menu--share {
  background: #2222227f;
  color: #fff;
  font-size: 1rem;
  line-height: $page_gap_2;

  border-radius: $radius_1;

  z-index: 1000;
  margin: $page_gap_2 $page_gap_1;

  text-align: center;

  @include interactive_default(('.pswp__button')) {
    height: initial !important;
    width: initial !important;
    margin: $page_gap_2 !important;
    padding: $page_gap_1 $page_gap_2 !important;
    display: block;
  }
}

// Google map

.pswp__google-map-container {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.pswp__google-map-container iframe {
  background: #444;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// blur

.pswp__bg_blur {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  backdrop-filter: blur(5px);

  // z-index: 100000;
  z-index: 99999;

  pointer-events: none;
}

.pswp__bg_blur {
  backdrop-filter: blur(0);
}

.backdropfilter {
  [class*=pswp] {
    will-change: initial !important;
  }
  
  .pswp__bg {
    background-color: #0000009f;
  }

  @include interactive_default(('.pswp__button')) {
    background: #7f7f7f3f !important;
    backdrop-filter: blur(5px);
    will-change: initial !important;
  }

  .pswp__counter, .pswp__custom-caption, .pswp__zoom-level-indicator, #pswp__menu--share {
    background: #1111115f;
    backdrop-filter: blur(5px);
  }
}

// show arrows on mobile

body .pswp--touch .pswp__button--arrow--prev,
body .pswp--touch .pswp__button--arrow--next {
  visibility: visible;
}
